import React from "react";
import { Card, Typography } from "@mui/material";

const WebsiteCard = ({ title, description, imageUrl, websiteUrl }) => {
  return (
    <Card
      elevation={0}
      style={{ backgroundColor: "transparent", textAlign: "center" }}
    >
      <a
        href={websiteUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <img
          src={imageUrl}
          alt={title}
          style={{ width: "100%", height: "auto", borderRadius: "10px" }}
        />
        <Typography
          variant="body1"
          component="p"
          style={{ color: "white", marginTop: "10px", fontSize: "18px" }}
        >
          {description}
        </Typography>
      </a>
    </Card>
  );
};

export default WebsiteCard;
