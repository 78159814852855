import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Header from "./Header";
import IntroSection from "./IntroSection";
import Footer from "./Footer";
import {
  MainContainer,
  ContentContainer,
  WebsiteCardStyled,
  neonText,
} from "./landingStyles";
import WebsiteCard from "./WebsiteCard";

function LandingPage() {
  const websites = [
    {
      title: "Stride",
      description: "Your AI Agent & Manager",
      imageUrl:
        "https://ik.imagekit.io/mbhxghyf1m2/Black%20Writer%20Logo%20(4)_hjeTh2mhz.png?updatedAt=1696625817548",
      websiteUrl: "https://www.stride-ai.com/",
    },
    {
      title: "Scripter",
      description: "AI Screenplay Narration",
      imageUrl:
        "https://ik.imagekit.io/mbhxghyf1m2/24a1fc4c-4fb6-4278-8bf0-211362b66ba8-transformed_vFEgrWgjz.webp?updatedAt=1697417917365",
      websiteUrl: "https://scripter-rouge.vercel.app/",
    },
    {
      title: "NextGen Media",
      description: "AI Content Production",
      imageUrl:
        "https://ik.imagekit.io/mbhxghyf1m2/NG_SQUARE_f01okiYlY.png?updatedAt=1696625991207",
      websiteUrl: "https://www.nextgenmediaai.com/",
    },
    {
      title: "StoryFlow AI",
      description: "AI Screenplays",
      imageUrl:
        "https://ik.imagekit.io/mbhxghyf1m2/Black%20White%20illustrative%20Reading%20Club%20Logo%20(8)_vo9jm7bhH.png?updatedAt=1696606539434",
      websiteUrl: "https://storyflowai.com/",
    },

    {
      title: "Auto AudioBooks",
      description: "AI Narration",
      imageUrl:
        "https://ik.imagekit.io/mbhxghyf1m2/Black%20and%20White%20Minimalist%20Monochrome%20Publishing%20Logo_ja-tZYmL4.png?updatedAt=1696606538464",
      websiteUrl: "https://aimedscribe.vercel.app/",
    },
    // {
    //   title: "Vibe AI",
    //   description: "Mobile AI Features",
    //   imageUrl:
    //     "https://ik.imagekit.io/mbhxghyf1m2/Futuristic%20Modern%20Black%20and%20White%20Logo%20(1)_Ry5XEdODU.png?updatedAt=1696886246671",
    //   websiteUrl: "https://vibe-landing-seven.vercel.app/",
    // },
    {
      title: "StoryTime",
      description: "Custom AI Stories For Kids",
      imageUrl:
        "https://ik.imagekit.io/mbhxghyf1m2/Blue%20and%20White%20Circuit%20Technology%20Logo%20(3)_8OgZkhH8Q.png?updatedAt=1696606538830",
      websiteUrl: "https://yacht-woad.vercel.app/",
    },
    {
      title: "Muse AI",
      description: "AI Digital Assistant",
      imageUrl:
        "https://ik.imagekit.io/mbhxghyf1m2/musei_UkiStl4gq.png?updatedAt=1696606623553",
      websiteUrl: "https://bitmuseai.com/",
    },

    // {
    //   title: "Link",
    //   description: "Location Aware Intros",
    //   imageUrl:
    //     "https://ik.imagekit.io/mbhxghyf1m2/Groovy%20Illustration%20Heart%20Logo%20(2)_dBMep_d5d.png?updatedAt=1696914238336",
    //   websiteUrl: "https://link-lemon.vercel.app/",
    // },
    // {
    //   title: "Shnarky",
    //   description: "Auto Text Reminders",
    //   imageUrl:
    //     "https://ik.imagekit.io/mbhxghyf1m2/DALL_E%202023-10-06%2009.42.02%20-%20Vector%20logo%20of%20a%20playful%20and%20modern%20shark%20named%20Shnarky,%20designed%20for%20an%20app%20company.%20The%20shark%20should%20have%20a%20friendly%20smile%20and%20be%20swimming%20dynamical_-RZFLAd_J.png?updatedAt=1696610536650",
    //   websiteUrl: "",
    // },
    // {
    //   title: "Rizzumé",
    //   description: "Roast Your Friends With AI",
    //   imageUrl:
    //     "https://ik.imagekit.io/mbhxghyf1m2/Groovy%20Illustration%20Heart%20Logo_-tiaYtHfl3.png?updatedAt=1696625918127",
    //   websiteUrl: "",
    // },
    // {
    //   title: "Resume Racer",
    //   description: "AI Resume Customizer",
    //   imageUrl:
    //     "https://ik.imagekit.io/mbhxghyf1m2/OIG_2_UY8GscCRk.png?updatedAt=1696608519578",
    //   websiteUrl: "",
    // },
  ];

  return (
    <MainContainer>
      <Header />

      <Container maxWidth="md" component={ContentContainer}>
        {/* <Typography
          variant="h4"
          style={{ ...neonText, marginBottom: "20px", marginTop: "20px" }}
        >
          Featured Projects
        </Typography> */}

        <Grid container spacing={3}>
          {websites.map((website, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <WebsiteCardStyled>
                <WebsiteCard
                  title={website.title}
                  description={website.description}
                  imageUrl={website.imageUrl}
                  websiteUrl={website.websiteUrl}
                />
              </WebsiteCardStyled>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Footer />
    </MainContainer>
  );
}

export default LandingPage;
