import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const neonText = {
  textShadow:
    "0 0 5px #0FF, 0 0 10px #0FF, 0 0 20px #0FF, 0 0 30px #FF00FF, 0 0 40px #FF00FF",
  color: "#0FF",
};

export const MainContainer = styled("div")(() => ({
  background: "linear-gradient(45deg, #000, #0FF, #000, #FF00FF, #000)",
  backgroundSize: "200% 200%",
  animation: "Gradient 3s ease infinite",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  "@keyframes Gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
}));

export const ContentContainer = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const WebsiteCardStyled = styled("div")({
  backdropFilter: "blur(10px)",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  border: "1px solid #0FF",
  boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  borderRadius: "10px",
  padding: "20px",
  "&:hover": {
    border: "2px solid #0FF",
  },
  animation: `${fadeIn} 0.5s ease-in`,
});
